
export class SkylarAPI {

	get_token() {

	}

	async userConversation( message, reset_subject ) {

		const body = {
			message,
			reset_subject
		};

		const response = await fetch('/api/user-conversation', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify( body )
		});

		return response.json();
	}


}
