export class AgentVizualizer {

	analyzer;
	source;
	stream;
	canvas;
	context;
	speaking;

	constructor( video ) {
		this.video = video;
		this.speaking = false;

		this.animate();
		this.setSpeaking( false );
		this.setThinking( false );
	}

	setSource( stream ) {
		this.audioContext = new AudioContext();
		this.source = this.audioContext.createMediaElementSource( stream );
		this.source.connect( this.audioContext.destination );
		this.analyzer = this.audioContext.createAnalyser();
		this.analyzer.fftSize = 32;
		this.analyzer.connect( this.audioContext.destination );
		this.source.connect( this.analyzer );

	}

	setSpeed( num ) {
		if ( this.video ) {
			this.video.playbackRate = num;
		}
	}

	setSpeaking( is_speaking ) {
		this.speaking = is_speaking;

		if ( is_speaking ) {
			this.setSpeed( 2 );
		} else {
			this.setSpeed( .5 );
			this.setScale( 1 );
		}
	}

	setThinking( is_thinking ) {
		this.thinking = is_thinking;

		if ( this.video ) {
			this.video.classList.toggle( 'thinking', is_thinking );
		}
	}

	animate() {
		requestAnimationFrame( () => this.animate() );
		if ( !this.speaking ) {
			return;
		}

		if ( !this.video ) {
			return;
		}
	
		const val = this.getVolume() / 255;
		const h = 1 + ( val * .5 );

		this.setScale( h );
	}

	setScale( scale ) {
		if ( this.video ) {
			this.video.style.scale = scale;
		}
	}

	getVolume() {
		const arr = new Uint8Array( this.analyzer.frequencyBinCount );
		this.analyzer.getByteFrequencyData( arr );
		return this.getAverage( arr );
	}

	getAverage( data ) {
		let values = 0;
		const length = data.length;
		for ( let i = 0; i<length; i++ ) {
			values += data[i];
		}

		return values / length;
	}


}

export default AgentVizualizer;