import axios from 'axios';

export class TextToSpeech {

	apiUrlBase = 'https://api.elevenlabs.io/v1/text-to-speech';
	sampleRate = 44100;

	constructor(apiKey, voiceID ) {
		this.apiKey = apiKey;
		this.voiceId = voiceID; // Sarah voice ID
	}

	async synthesizeSpeech(text) {
		try {
			const response = await axios.post(
				`https://api.elevenlabs.io/v1/text-to-speech/${this.voiceId}`,
				{ text },
				{
					headers: {
						'Accept': 'audio/mpeg',
						'xi-api-key': this.apiKey,
						'Content-Type': 'application/json'
					},
					responseType: 'arraybuffer'
				}
			);
			return new Blob([response.data], { type: 'audio/mpeg' });
		} catch (error) {
			console.error('Error synthesizing speech:', error);
			throw error;
		}
	}

	async streamSpeech( text ) {
		const url = `${this.apiUrlBase}/${this.voiceId}/stream/with-timestamps`;

		const headers = {
			'Accept': 'audio/mpeg',
			'xi-api-key': this.apiKey,
			'Content-Type': 'application/json'
		};

		const payload = {
			text
		};

		const response = await fetch( url, {
			method: 'POST',
			headers,
			body: JSON.stringify( payload )
		});

		if ( !response.ok ) {
			console.error( 'Error:', response.statusText );
			return;
		}

		const audioContext = new ( window.AudioContext || window.webkitAudioContext )();
		
		const reader = response.body.getReader();
		const decoder = new TextDecoder( 'utf-8' );
		const streamData = [];
		const audioData = [];
		const alignmentData = [];

		while( true ) {
			const { done, value } = await reader.read();
			if ( done ) {
				break;
			}

			streamData.push( decoder.decode( value ) );
		}

		const chunks = streamData.join('').split('\n');
		for ( const ch of chunks ) {
			if ( !ch ) { continue; }

			const data = JSON.parse( ch );
			if ( data.audio_base64 ) {
				const binary = Uint8Array.from( atob( data.audio_base64 ), c => c.charCodeAt(0) );
				audioData.push( binary );
			}

			// @todo: data.alignment
			if ( data.alignment ) {
				alignmentData.push( data.alignment );
			}

		}

		const audioBlob = new Blob( audioData, { type: 'audio/mpeg' });
		return {
			audiodata: audioBlob,
			messagedata: this.processCharAlignment( alignmentData )
		};

		/*
		const bloburl = URL.createObjectURL( audioBlob );
		const player = new Audio( bloburl );
		player.play();
		*/


		/*
		console.log( audioBlob );
		console.log( URL.createObjectURL( audioBlob ) );
		*/

		/*
		const fileReader = new FileReader();
		reader.onload = e => {
			const arrayBuffer = e.target.result;

			audioContext.decodeAudioData( arrayBuffer )
				.then( buffer => this.playAudioBuffer( buffer ) );

		};

		fileReader.readAsArrayBuffer( audioBlob );
		*/

		/*
		const audio = audioData.join('');
		const audioBuffer = audioContext.createBuffer( 1, audio.length, this.sampleRate );
		audioBuffer.copyToChannel( new Float32Array( audio ), 0 );

		const source = audioContext.createBufferSource();
		source.buffer = audioBuffer;
		source.connect( audioContext.destination );
		source.start();

		await new Promise( resolve => {
			// Wait until audio finishes before streaming next chunk.
			source.onended = resolve;
		});

		*/

	}

	playAudioBuffer( buffer ) {
		const source = this.audioContext.createBufferSource();
		source.buffer = buffer;
		source.connect( audioContext.destination );
		source.start();
		return source;
	}

	processCharAlignment( chunks ) {
		const chars = [];
		const start = [];

		for ( const ch of chunks ) {
			const { characters, character_start_times_seconds } = ch;
			for ( let i=0,n=characters.length; i<n; i++ ) {
				chars.push(characters[i]);
				start.push(character_start_times_seconds[i]);
			}
		}

		return {
			segment: chars,
			start
		};
	}

	// Reformat character alignments to word alignments.
	processWordAlignment( chunks ) {
		const words = [];
		const start = [];

		let word = '';
		for ( const ch of chunks ) {
			const { characters, character_start_times_seconds } = ch;
			for ( let i=0,n=characters.length; i<n; i++ ) {
				const char = characters[i];
				if ( word === '' ) {
					start.push( character_start_times_seconds[i] );
				}

				if ( char === ' ' ) {
					words.push( word );
					word = '';
				} else {
					word += char;
				}
			}

			// Don't forget last word!
			if ( word ) {
				words.push( word );
			}
		}

		return {
			segment: words,
			start
		};
	}

}
