import InterviewClient from './InterviewClient';

import '../css/styles.css';

class App {
	constructor( config ) {
		config = this.settings = this.parseConfig( config );
		this.interview = new InterviewClient( config );

		if ( config.audio ) {
			const useClick = config.audio && config.talk_trigger ? true : false;
			this.playAudio( config, useClick );
		}

		if ( config.talk_trigger ) {
			this.hotkeyListener( config.talk_trigger );
		}
	}

	parseConfig( settings ) {
		const defaults = {
			audioinit: false,
			interview: null,
			start: false,
			audio: null,
			after_audio: null,
			talk_trigger: null
		};

		const config = {};

		for( const prop in defaults ) {
			config[prop] = ( prop in settings ) ? settings[prop] : defaults[prop];
		}

		return config;
	}

	playAudio( config, useClick ) {
		const audio = new Audio( config.audio );
		
		if ( config.after_audio ) {
			audio.addEventListener( 'ended', () => {
				location.href = config.after_audio;
			});
		}

		audio.play()
			.catch( (e) => {
				const dialog = this.audioModal( useClick );
				const closeModal = () => {
					dialog && dialog.close();
					audio.play();
				};

				if ( useClick ) {
					dialog.addEventListener( 'click', closeModal );
				} else {
					this.hotkeyListener( closeModal );
				}

				dialog && dialog.showModal();
			});
	}

	audioModal( clickMessage) {
		let dialog = document.querySelector( '#talk_notice' );

		if ( !dialog ) {
			dialog = document.createElement( 'dialog' );
			dialog.id = 'talk_notice';
			const txt = document.createElement( 'p' );
			dialog.appendChild( txt );
			document.body.appendChild( dialog );
		}

		const message = clickMessage ? 'Click to continue' : 'Press the TALK button to continue';
		dialog.querySelector('p').textContent = message;

		return dialog;
	}

	hotkeyListener( down, up, keycode ) {
		keycode = keycode || 'Space';
		if ( down ) {
			document.addEventListener( 'keydown', e => {
				if ( e.code == keycode ) {
					e.preventDefault();
					if ( e.repeat ) { return; }
					down(e);
				}
			});
		}

		if ( up ) {
			document.addEventListener( 'keyup', e => {
				if ( e.code == keycode ) {
					e.preventDefault();
					up(e);
				}
			});
		}
	}
}

document.addEventListener('DOMContentLoaded', () => {
	new App( global.synapse_settings || {} );
});

